import React, { memo, useCallback } from 'react';
import { WsEventHandler } from '@cometph/frontend-core/api';
import useWebSocket from 'react-use-websocket';
import { useAuth } from '@cometph/frontend-core/contexts';
import styles from './MarketStatus.module.scss';
import colors from '../../../../styles/_colors.scss';
import { MarketStatus, updateMarketStatus, useIsAppReady, useMarketStatus } from 'common/store/appReducer';
import { useDispatch } from 'react-redux';
import { AppConfig } from '@cometph/frontend-core/helpers';
import useIsTabVisible from 'common/hooks/useIsTabVisible';

type StatusMessage = {
  currentStatus: MarketStatus;
};

const labelMap: Record<MarketStatus, string> = {
  [MarketStatus.Close]: 'CLOSED',
  [MarketStatus.PreOpen]: 'PRE-OPEN',
  [MarketStatus.PreCloseNoCancel]: 'NO-CANCEL',
  [MarketStatus.PreOpenNoCancel]: 'NO-CANCEL',
  [MarketStatus.Open]: 'OPEN',
  [MarketStatus.Break]: 'BREAK',
  [MarketStatus.PreClose]: 'PRE-CLOSE',
  [MarketStatus.TradingAtLast]: 'TAL',
  [MarketStatus.Loading]: 'LOADING...',
};

const statusColors = {
  active: { color: colors.textGreen, backgroundColor: colors.backgroundGreen },
  inactive: { color: colors.textRed, backgroundColor: colors.backgroundRed },
  pending: { color: colors.textYellow, backgroundColor: colors.backgroundYellow },
  loading: { color: colors.gray600, backgroundColor: colors.gray300 },
};

const colorMap: Record<MarketStatus, { color: string; backgroundColor: string }> = {
  [MarketStatus.Close]: statusColors.inactive,
  [MarketStatus.PreOpen]: statusColors.pending,
  [MarketStatus.PreCloseNoCancel]: statusColors.pending,
  [MarketStatus.PreOpenNoCancel]: statusColors.pending,
  [MarketStatus.Open]: statusColors.active,
  [MarketStatus.Break]: statusColors.inactive,
  [MarketStatus.PreClose]: statusColors.pending,
  [MarketStatus.TradingAtLast]: statusColors.pending,
  [MarketStatus.Loading]: statusColors.loading,
};

export const MarketStatusIndicator = memo(function MarketStatus() {
  const isTabVisible = useIsTabVisible();
  const isReady = useIsAppReady();
  const { token, isTokenValid } = useAuth();
  const status = useMarketStatus();
  const dispatch = useDispatch();
  const handleMessage: WsEventHandler = useCallback(
    ({ currentStatus }: Partial<StatusMessage>) => {
      if (currentStatus) {
        dispatch(updateMarketStatus(currentStatus));
      }
    },
    [dispatch]
  );

  useWebSocket(
    `${AppConfig.MARKET_STATUS_WS_URL}?Authorization=${token}`,
    {
      onMessage: (event) => {
        handleMessage(JSON.parse(event.data));
      },
    },
    isTokenValid && (isReady || !isTabVisible)
  );

  return (
    <div className={styles.container}>
      <div className={styles.label}>Market status</div>
      <div className={styles.badge} style={colorMap[status]}>
        {labelMap[status]}
      </div>
    </div>
  );
});
